import React, { useCallback, useEffect, useState } from "react";
import {
	DialogContent,
	DialogTitle,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Dialog,
} from "@mui/material";
// Icon imports
import { makeStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";
import TableStyle from "styles/application/TableStyle";
import AddDialogStyle from "styles/application/AddDialogStyle";
import ColourConstants from "helpers/colourConstants";
import { handleSort } from "helpers/utils";
import GeneralButton from "components/Elements/GeneralButton";
import { restoreDeletedModels } from "services/models/modelList";
import { showError, showSuccess } from "../../../../redux/common/actions";
import AutoFitContentInScreen from "components/Layouts/AutoFitContentInScreen";
// Init styled components
const AT = AddDialogStyle();
const MT = TableStyle();
const media = "@media (max-width:414px)";

const useStyles = makeStyles()((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		gap: 5,
		marginBottom: 15,
	},
	tableHeadRow: {
		borderBottomColor: ColourConstants.tableBorder,
		borderBottomStyle: "solid",
		borderBottomWidth: 1,
		backgroundColor: ColourConstants.tableBackground,
		fontWeight: "bold",
		borderRightColor: "#979797",
		borderRightStyle: "solid",
		borderRightWidth: "1px",
	},
	button: {
		marginTop: 15,
		width: "30%",
		[media]: {
			width: "auto",
		},
	},
	loading: {
		position: "absolute",
		width: "100%",
		left: 0,
		top: 0,
	},

	tableBody: {
		whiteSpace: "noWrap",
	},
	selectedTableHeadRow: {
		borderBottomColor: ColourConstants.tableBorder,
		borderBottomStyle: "solid",
		borderBottomWidth: 1,
		backgroundColor: ColourConstants.tableBackgroundSelected,
		fontWeight: "bold",
		color: "#FFFFFF",
	},
	nameRow: {
		width: 130,
		[media]: {
			width: 130,
			// width: "auto",
		},
	},
}));

const RestoreModalDialog = ({
	open,
	handleClose,
	headers,
	columns,
	data,
	isMounted,
}) => {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();

	const [restoredData, setRestoredData] = useState(data);
	const [fetchLoading, setFetchLoading] = useState(false);
	// Init State
	const [currentTableSort, setCurrentTableSort] = useState(["name", "asc"]);
	const closeOverride = () => {
		handleClose();
	};
	//display error popup
	const displayError = (errorMessage, response) =>
		dispatch(
			showError(
				response?.data?.detail || errorMessage || "Something went wrong"
			)
		);
	const displaySuccess = (successMessage, response) =>
		dispatch(
			showSuccess(
				response?.data?.detail || successMessage || "Something went wrong"
			)
		);
	// Handlers
	const handleSortClick = (field) => {
		// Flipping current method
		const newMethod =
			currentTableSort[0] === field && currentTableSort[1] === "asc"
				? "desc"
				: "asc";

		handleSort(data, setRestoredData, field, newMethod);
		// Updating header state
		setCurrentTableSort([field, newMethod]);
	};
	const fetchDeletedModels = useCallback(async (modelId) => {
		setFetchLoading(true);
		const response = await restoreDeletedModels(modelId);
		if (!isMounted.aborted) {
			if (response.status) {
				displaySuccess("Model Successfully Restored!", {
					status: true,
					message: "Model Successfully Restored!",
					data: "Model Successfully Restored!",
				});
				// Update the data array, removing the restored model
				setRestoredData(
					(prevData) => prevData?.filter((d) => d.id !== modelId) || []
				);

				setFetchLoading(false);
			} else {
				setFetchLoading(false);
				displayError(response?.data, response);
			}
		} else {
			return;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (data) {
			setRestoredData(data);
		}
	}, [data]);
	return (
		<>
			<Dialog open={open} onClose={closeOverride} fullWidth maxWidth="md">
				<AT.ActionContainerFileUpload>
					<DialogTitle style={{ width: "full", flex: "1" }}>
						<AT.HeaderText>Restore Model</AT.HeaderText>
					</DialogTitle>
					<AT.NoteButtonContainer>
						<AT.CancelButton
							variant="contained"
							onClick={() => {
								closeOverride();
							}}
						>
							Close
						</AT.CancelButton>
					</AT.NoteButtonContainer>
				</AT.ActionContainerFileUpload>
				<div
					style={{
						minHeight: "400px",
						overflow: "hidden ",
						padding: "16px 24px",
					}}
				>
					{fetchLoading && <LinearProgress className={classes.loading} />}
					<AutoFitContentInScreen containsTable={true}>
						<Table aria-label="Table">
							<MT.TableHead>
								<TableRow>
									{headers.map((header, i) => (
										<TableCell
											key={i}
											onClick={() => {
												if (header?.toLowerCase() !== "restore") {
													handleSortClick(columns[i]);
												}
											}}
											className={cx(classes.nameRow, {
												[classes.selectedTableHeadRow]:
													currentTableSort[0] === columns[i],
												[classes.tableHeadRow]:
													currentTableSort[0] !== columns[i],
											})}
										>
											<MT.CellContainer>
												{header?.toLowerCase() === "restore" ? "" : header}
												{header?.toLowerCase() !== "restore" && (
													<>
														{currentTableSort[0] === columns[i] &&
															currentTableSort[1] === "desc" ? (
															<MT.DefaultArrow fill="#FFFFFF" />
														) : (
															<MT.DescArrow fill="#FFFFFF" />
														)}
													</>
												)}
											</MT.CellContainer>
										</TableCell>
									))}
								</TableRow>
							</MT.TableHead>
							<TableBody className={classes.tableBody}>
								{restoredData.length ? (
									restoredData.map((row, index) => (
										<TableRow key={row.id}>
											{columns.map((col, i, arr) => {
												return (
													<MT.DataCell key={col}>
														<MT.CellContainer key={col}>
															<MT.TableBodyText>{row[col]}</MT.TableBodyText>
															{arr.length === i + 1 ? (
																<GeneralButton
																	style={{
																		backgroundColor: "#23bb79",
																		marginLeft: "50px",
																	}}
																	onClick={() => {
																		fetchDeletedModels(row?.id);
																	}}
																>
																	Restore
																</GeneralButton>
															) : null}
														</MT.CellContainer>
													</MT.DataCell>
												);
											})}
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell>No any records found</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</AutoFitContentInScreen>
				</div>
			</Dialog>
		</>
	);
};

export default RestoreModalDialog;
