import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import ArrowIcon from "assets/icons/arrowIcon.svg";
import CurveButton from "components/Elements/CurveButton";
import { getLocalStorageData, handleSort } from "helpers/utils";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	addClientSite,
	updateClientSiteName,
	updateClientSiteRegionName,
} from "services/clients/clientDetailScreen";
import CommonAddDialog from "pages/Clients/ClientDetailScreen/CommonAddDialog";
import { siteDetailPath, clientsPath, appPath } from "helpers/routePaths";
import UpdateSiteDialog from "./UpdateSiteDialog";
import UpdateRegionDialog from "./UpdateRegionDialog";
import { READONLY_ACCESS } from "constants/AccessTypes/AccessTypes";
import { RESELLER_ID } from "constants/UserConstants/indes";
import roles from "helpers/roles";
import ColourConstants from "helpers/colourConstants";

// Constants
const SUMMARY_COLOR = "#EDEDF4";

const useStyles = makeStyles()((theme) => ({
	accordionParent: {
		borderStyle: "solid",
		borderColor: "#000000",
		borderWidth: "1px",
		marginBottom: "15px",
	},
	summary: {
		margin: 0,
		backgroundColor: SUMMARY_COLOR,
	},
	summaryContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	detailsContainer: {
		borderBottom: "1px solid rgba(0, 0, 0, .125)",
		borderTop: "1px solid rgba(0, 0, 0, .125)",
		padding: "14px 20px",
		alignItems: "center",
		position: "relative",
	},
	regionText: {
		fontWeight: "bold",
	},
	siteText: {
		fontWeight: "bold",
		paddingRight: "3px",
		margin: 0,
	},
	editIconRegion: {
		paddingRight: "3px",
		margin: "auto",
		position: "absolute",
		right: 40,
		top: "auto",
		cursor: "pointer",
		textDecoration: "underline",
		"&:hover": {
			cursor: "pointer",
		},
		fontSize: "14px",
		color: ColourConstants.activeLink,
	},
	editIconSite: {
		paddingRight: "3px",
		margin: "auto",
		position: "absolute",
		right: 20,
		top: 0,
		cursor: "pointer",
		textDecoration: "underline",
		"&:hover": {
			cursor: "pointer",
		},
		fontSize: "14px",
		color: ColourConstants.activeLink,
	},
	expandIcon: {
		transform: "scale(0.8)",
	},
	statusSwitch: {
		marginLeft: "auto",
	},
	singleRegion: {
		marginBottom: "30px",
	},
	addButton: {
		textAlign: "right",
		padding: "8px 0",
	},
	siteLink: {
		color: "#307AD6",
		margin: "0 4px",
	},
	positionRelative: { position: "relative" },
}));

function Region({ region, fetchRegionsAndSites, getError, clientId }) {
	const { id, name, sites } = region;
	const { classes } = useStyles();
	const { position, role, adminType } = getLocalStorageData("me");
	const [openAddDialog, setOpenAddDialog] = useState(false);
	const [openUpdateSiteDialog, setOpenUpdateSiteDialog] = useState(false);
	const [openUpdateRegionDialog, setOpenUpdateRegionDialog] = useState(false);
	const [siteInput, setSiteInput] = useState("");
	const [editRegionInput, setEditRegionInput] = useState({});
	const [editSiteInput, setEditSiteInput] = useState({ reference: "" });
	const [modifiedSites, setModifiedSites] = useState([]);

	//add site to a specific region
	const onAddSite = async (e) => {
		e.preventDefault();

		let result = await addClientSite({
			regionID: id,
			name: siteInput,
		});

		if (result.status) {
			await fetchRegionsAndSites();

			return { success: true };
		} else {
			// Throwing response if error
			if (
				result.data.errors !== undefined &&
				result.data.detail === undefined
			) {
				return { success: false, errors: result.data.errors };
			} else if (
				result.data.errors !== undefined &&
				result.data.detail !== undefined
			) {
				return {
					success: false,
					errors: { name: result.data.detail },
				};
			} else {
				setOpenAddDialog(false);
				getError("Something went wrong!");

				return false;
			}
		}
	};
	// Edit region
	const onEditRegion = async (regionId, regionName) => {
		let result = await updateClientSiteRegionName(regionId, {
			label: "name",
			value: regionName,
		});

		if (result.status) {
			await fetchRegionsAndSites();
			return { success: true };
		} else {
			// Throwing response if error
			if (
				result.data.errors !== undefined &&
				result.data.detail === undefined
			) {
				return { success: false, errors: result.data.errors };
			} else if (
				result.data.errors !== undefined &&
				result.data.detail !== undefined
			) {
				return {
					success: false,
					errors: { name: result.data.detail },
				};
			} else {
				setOpenAddDialog(false);
				getError("Something went wrong!");
				return false;
			}
		}
	};

	// Edit site
	const onEditSite = async (siteId, siteName) => {
		let result = await updateClientSiteName(siteId, {
			label: "name",
			value: siteName,
		});

		if (result.status) {
			await fetchRegionsAndSites();
			return { success: true };
		} else {
			// Throwing response if error
			if (
				result.data.errors !== undefined &&
				result.data.detail === undefined
			) {
				return { success: false, errors: result.data.errors };
			} else if (
				result.data.errors !== undefined &&
				result.data.detail !== undefined
			) {
				return {
					success: false,
					errors: { name: result.data.detail },
				};
			} else {
				setOpenAddDialog(false);
				getError("Something went wrong!");
				return false;
			}
		}
	};

	// Check if access is read-only or user is Superadmin, Client Admin, or Reseller
	const EDIT_ACCESS =
		position?.settingAccess !== READONLY_ACCESS ||
		adminType === RESELLER_ID ||
		role === roles?.superAdmin ||
		role === roles?.clientAdmin;

	useEffect(() => {
		handleSort(sites, setModifiedSites, "name", "asc");
	}, [sites]);

	return (
		<div className={classes.singleRegion}>
			{openAddDialog && (
				<CommonAddDialog
					open={openAddDialog}
					closeHandler={() => setOpenAddDialog(false)}
					label="Site"
					input={siteInput}
					setInput={setSiteInput}
					createHandler={onAddSite}
					reference={name}
				/>
			)}
			{openUpdateSiteDialog && (
				<UpdateSiteDialog
					open={openUpdateSiteDialog}
					closeHandler={() => setOpenUpdateSiteDialog(false)}
					siteInputs={editSiteInput}
					setInput={setEditSiteInput}
					createSiteHandler={onEditSite}
					reference={editSiteInput?.reference}
					clientId={clientId}
				/>
			)}
			{openUpdateRegionDialog && (
				<UpdateRegionDialog
					open={openUpdateRegionDialog}
					closeHandler={() => setOpenUpdateRegionDialog(false)}
					regionInputs={editRegionInput}
					setInput={setEditRegionInput}
					createRegionHandler={onEditRegion}
					reference={name}
				/>
			)}
			<Accordion className={classes.accordionParent} defaultExpanded={true}>
				<AccordionSummary
					className={classes.summary}
					expandIcon={
						<img
							alt="Expand icon"
							src={ArrowIcon}
							className={classes.expandIcon}
						/>
					}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<div className={classes.summaryContainer}>
						<div className={classes.summaryContent}>
							<Typography className={classes.regionText}>
								{name} ({sites.length} {sites.length === 1 ? "Site" : "Sites"})
							</Typography>
						</div>
						{EDIT_ACCESS && (
							<Typography
								className={classes.editIconRegion}
								onClick={(e) => {
									setOpenUpdateRegionDialog(true);
									setEditRegionInput({
										id,
										name,
									});
									e.stopPropagation();
								}}
							>
								Edit
							</Typography>
						)}
					</div>
				</AccordionSummary>

				{modifiedSites.map((site) => (
					<AccordionDetails className={classes.detailsContainer} key={site.id}>
						<Typography gutterBottom className={classes.siteText}>
							Site:
						</Typography>
						<Typography className={classes.positionRelative}>
							<Link
								className={classes.siteLink}
								to={`${appPath}${clientsPath}/${clientId}/sites/${site.id}/${siteDetailPath}`}
							>
								{site.name}
							</Link>
							{EDIT_ACCESS && (
								<Typography
									className={classes.editIconSite}
									onClick={() => {
										setOpenUpdateSiteDialog(true);
										setEditSiteInput({
											reference: site.name,
											name: site.name,
											id: site.id,
										});
									}}
								>
									Edit
								</Typography>
							)}
						</Typography>
					</AccordionDetails>
				))}
			</Accordion>
			<AccordionActions className={classes.addButton}>
				<CurveButton onClick={() => setOpenAddDialog(true)}>
					Add Site
				</CurveButton>
			</AccordionActions>
		</div>
	);
}

export default Region;
