import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import AddDialogStyle from "styles/application/AddDialogStyle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import ColourConstants from "helpers/colourConstants";

const ADD = AddDialogStyle();

const useStyles = makeStyles()((theme) => ({
	dialogContent: {
		width: 500,
		display: "flex",
		flexDirection: "column",
		gap: "4px",
	},
	createButton: {
		width: "auto",
	},
	cancelButton: {
		width: "90px",
	},
	siteContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		gap: "4px",
	},
	required: {
		color: "red",
	},
}));

const UpdateSiteDialog = ({
	open,
	closeHandler,
	createSiteHandler,
	label,
	siteInputs,
	setInput,
	reference,
	clientId,
}) => {
	const { classes } = useStyles();
	const [isUpdating, setIsUpdating] = useState(false);
	const [error, setError] = useState({ isError: false });
	const [isDisabled, setIsDisabled] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (error.isError) {
			setError({ isError: false });
		}

		if (name === "site") {
			setInput((prevInputs) => ({
				...prevInputs,
				name: value,
				id: siteInputs.id,
			}));
		}
	};

	const onAddSite = async (e) => {
		e.preventDefault();
		const isAnyInputEmpty = Object.values(siteInputs).some(
			(input) => input === ""
		);

		if (isAnyInputEmpty) {
			setError({ name: "All fields are required", isError: true });
		} else {
			setIsDisabled(true);
			setIsUpdating(true);
			const result = await createSiteHandler(siteInputs.id, siteInputs.name);

			if (result.success) {
				closeHandler();
			} else {
				if (result.errors) {
					setError({ ...result.errors, isError: true });
					setIsUpdating(false);
					setIsDisabled(false);
				}
			}
		}
	};

	const handleEnterPress = (e) => {
		if (e.keyCode === 13) {
			onAddSite(e);
		}
	};

	useEffect(() => {
		return () => {
			setInput({ region: "", site: "" });
			setError({});
			setIsDisabled(false);
			setIsUpdating(false);
		};
	}, []);

	return (
		<Dialog
			open={open}
			onClose={closeHandler}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{isUpdating && <LinearProgress />}

			<ADD.ActionContainer>
				<DialogTitle id="alert-dialog-title">
					<ADD.HeaderText>
						Edit Site{label} {reference && `(${reference})`}
					</ADD.HeaderText>
				</DialogTitle>
				<ADD.ButtonContainer>
					<ADD.CancelButton
						onClick={closeHandler}
						variant="contained"
						className={classes.cancelButton}
						sx={{
							"&.MuiButton-root:hover": {
								backgroundColor: ColourConstants.deleteDialogHover,
								color: "#ffffff",
							},
						}}
					>
						Cancel
					</ADD.CancelButton>
					<ADD.ConfirmButton
						onClick={(e) => {
							onAddSite(e);
						}}
						variant="contained"
						className={classes.createButton}
						disabled={isDisabled}
						sx={{
							"&.MuiButton-root:hover": {
								backgroundColor: ColourConstants.deleteDialogHover,
								color: "#ffffff",
							},
						}}
					>
						Close
					</ADD.ConfirmButton>
				</ADD.ButtonContainer>
			</ADD.ActionContainer>

			<DialogContent className={classes.dialogContent}>
				<ADD.InputContainer>
					<ADD.NameInput
						autoFocus
						error={error.isError}
						helperText={error.isError ? error.name : null}
						required
						label="Site"
						name="site"
						value={siteInputs.name}
						onKeyDown={handleEnterPress}
						onChange={handleInputChange}
					/>
				</ADD.InputContainer>
			</DialogContent>
		</Dialog>
	);
};

export default UpdateSiteDialog;
