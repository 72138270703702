import React from "react";
import { connect } from "react-redux";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { hideSuccess } from "../../redux/common/actions";
import CurveButton from "./CurveButton";

const useStyles = makeStyles()((theme) => {
	return {
		dialogTitle: {
			backgroundColor: "#23bb79",
			color: "#FFFFFF",
			display: "flex",
			justifyContent: "center",
		},
		dialogAction: {
			display: "flex",
			justifyContent: "center",
		},
	};
});

const SuccessDialog = ({ success, removeSuccess }) => {
	const { classes, cx } = useStyles();
	const handleClose = () => removeSuccess();

	return (
		<Dialog
			open={success.status}
			onClose={handleClose}
			fullWidth
			style={{ position: "absolute" }}
		>
			<DialogTitle className={classes.dialogTitle}>
				<CheckCircleOutline style={{ fontSize: "9rem" }} />
			</DialogTitle>
			<DialogContent style={{ textAlign: "center" }}>
				<h1>{success.message}</h1>
			</DialogContent>
			<DialogActions className={classes.dialogAction}>
				<CurveButton
					style={{ backgroundColor: "#23bb79" }}
					onClick={handleClose}
				>
					Dismiss
				</CurveButton>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = ({ commonData: { success } }) => ({ success });
const mapDispatchToProps = (dispatch) => ({
	removeSuccess: () => dispatch(hideSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessDialog);
